/*
 * Be careful adding global styles to this file
 * Component styles should be locally scoped and explicitly declared
 */

@import '_reset';
@import '_fonts';

/* // https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-y: scroll;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-size: 1.6rem;
  font-family: 'OVOCircular', Futura, 'Century Gothic', sans-serif;
  line-height: 1.5;
  color: #2f3749;
  background-color: #f9f6f3;
}

img,
svg {
  max-width: 100%;
}
img {
  height: auto;
}

/* // Fallback for older browsers */
[hidden] {
  display: none;
}
